import { useClickAway } from "@uidotdev/usehooks";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  fetchSearchSuggestions,
  updateJobListFilter,
  updateJobSearchKeyword,
} from "../../../redux/actions/job_action";
import {
  BoxContainer,
  ButtonStyled,
  ClearIcon,
  InputBaseStyled,
  NavbarSearchStyled,
  SearchDropdownContainer,
  SearchIcon,
  SearchSuggestionContainer,
} from "./styles";

function SearchBar(props) {
  const { searchKeyword } = props;

  const dispatch = useDispatch();
  const router = useRouter();

  const jobFilterKeyword = useSelector(
    (state) => state.jobs?.jobListFilter?.keyword
  );
  const currentSearch = useSelector((state) => state.jobs?.jobSearchKeyword);
  const searchSuggestion = useSelector((state) => state.jobs?.searchData);

  const [searchBarFocus, setSearchBarFocus] = useState(false);

  const searchbarRef = useClickAway(() => {
    setSearchBarFocus(false);
  });

  function handleFocusSearch(event) {
    event.preventDefault();
    event.stopPropagation();

    setSearchBarFocus(true);
  }

  function handleInputSearch(event) {
    const keyword = event.target.value;
    dispatch(updateJobSearchKeyword({ keyword: keyword }));
    debounceSearchSuggestions({ keyword: keyword });
  }

  const debounceSearchSuggestions = debounce((params) => {
    dispatch(fetchSearchSuggestions(params));
  }, 300);

  function boldMatchingText(suggestion) {
    const keyword = currentSearch.toLowerCase();

    const lowerCaseSuggestion = suggestion.toLowerCase();
    const index = lowerCaseSuggestion.indexOf(keyword);

    if (!keyword || index === -1) {
      return (
        <SearchSuggestionContainer
          onClick={() => handleTriggerSearch(suggestion)}
        >
          {suggestion}
        </SearchSuggestionContainer>
      );
    }

    const beforeMatch = suggestion.substring(0, index);
    const match = suggestion.substring(index, index + keyword.length);
    const afterMatch = suggestion.substring(index + keyword.length);

    return (
      <SearchSuggestionContainer
        onClick={() => handleTriggerSearch(suggestion)}
      >
        <span>
          {beforeMatch}
          <b>{match}</b>
          {afterMatch}
        </span>
      </SearchSuggestionContainer>
    );
  }

  function handleTriggerSearch(searchTerm) {
    scrollTo(0, 0);
    setSearchBarFocus(false);

    const keyword = searchTerm ? searchTerm : currentSearch;
    const query = router.query;

    if (!keyword) {
      handleClearSearch();
    }

    dispatch(updateJobListFilter({ keyword: keyword })).then(() => {
      dispatch(updateJobSearchKeyword({ keyword: keyword }));
      const { filter, ...queryParams } = query;

      const filteredQuery =
        query.filter.includes("jobs") ||
        query.filter.some((item) => item.endsWith("-jobs"))
          ? query.filter.filter(
              (item) => !item.includes("jobs") && !item.endsWith("-jobs")
            )
          : query.filter;

      const cleanedQuery = filteredQuery.map((item) => {
        if (item.startsWith("jobs-in-")) {
          return item.replace("jobs-", "");
        }
        return item;
      });

      const keywordSlug =
        keyword.toLowerCase()?.replace(/\s+/g, "-") + "-jobs/";
      const slug = keywordSlug + cleanedQuery.join("/");

      if (searchTerm) {
        sendTrackingEvent({
          event: "CE_search-job-suggestion-job-list",
          "search-term": `${searchTerm}`,
        });
      } else {
        sendTrackingEvent({
          event: "CE_search-job-job-list",
          "search-term": `${
            currentSearch && currentSearch.length > 0 ? currentSearch : ""
          }`,
        });
      }

      const { page, ...rest } = queryParams;
      const cleanedQueryParams = rest;

      router.push(
        {
          pathname: slug ? slug : "/jobs",
          query: cleanedQueryParams,
        },
        undefined,
        { shallow: true }
      );
    });
  }

  function handleEnterSearch(event) {
    if (event.key === "Enter") {
      handleTriggerSearch();
    }
  }

  function handleClearSearch(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const query = router.query;

    dispatch(updateJobListFilter({ keyword: "" })).then(() => {
      dispatch(updateJobSearchKeyword({ keyword: "" }));
      const { filter, ...queryParams } = query;

      const filteredQuery = query.filter.filter(
        (item) => !item.endsWith("-jobs")
      );

      const cleanedQuery = filteredQuery.map((item, index) => {
        if (item.startsWith("in-") && index === 0) {
          return item.replace("", "jobs-");
        }
        return item;
      });

      const slug = cleanedQuery.join("/");

      router.push({
        pathname: slug ? slug : "/jobs",
        query: queryParams,
      });
    });
  }

  useEffect(() => {
    if (searchKeyword !== undefined) {
      if (searchKeyword) {
        dispatch(updateJobSearchKeyword({ keyword: searchKeyword }));
      }
    }
  }, [dispatch, searchKeyword]);

  return (
    <Fragment>
      <NavbarSearchStyled
        component="div"
        elevation={0}
        $active={searchBarFocus}
      >
        <InputBaseStyled
          ref={searchbarRef}
          placeholder={"Search Jobs"}
          inputProps={{ "aria-label": "discover job search" }}
          onClick={handleFocusSearch}
          onChange={handleInputSearch}
          onKeyDown={handleEnterSearch}
          value={currentSearch}
        />
        <BoxContainer alignItems={"center"} gap={"0.25rem"}>
          {jobFilterKeyword?.length > 0 && (
            <ButtonStyled onClick={handleClearSearch}>
              <ClearIcon />
            </ButtonStyled>
          )}

          <ButtonStyled
            aria-label="search button"
            onClick={() => handleTriggerSearch()}
          >
            <SearchIcon />
          </ButtonStyled>
        </BoxContainer>
      </NavbarSearchStyled>
      {searchBarFocus &&
      currentSearch?.length > 2 &&
      searchSuggestion?.length > 0 ? (
        <SearchDropdownContainer ref={searchbarRef}>
          {searchSuggestion.map((data, index) => {
            return <div key={index}>{boldMatchingText(data.name)}</div>;
          })}
        </SearchDropdownContainer>
      ) : null}
    </Fragment>
  );
}

export default SearchBar;
