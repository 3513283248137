import ArrowLeftIcon from "@heroicons/react/16/solid/ArrowLeftIcon";
import FireIcon from "@heroicons/react/16/solid/FireIcon";
import MapPinIcon from "@heroicons/react/16/solid/MapPinIcon";
import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import BookmarkOutlineIcon from "@heroicons/react/24/outline/BookmarkIcon";
import EyeSlashIcon from "@heroicons/react/24/outline/EyeSlashIcon";
import SparklesOutlineIcon from "@heroicons/react/24/outline/SparklesIcon";
import BookmarkFilledIcon from "@heroicons/react/24/solid/BookmarkIcon";
import GlobeAsiaAustraliaIcon from "@heroicons/react/24/solid/GlobeAsiaAustraliaIcon";
import SparklesIcon from "@heroicons/react/24/solid/SparklesIcon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../../assets/colors";

export const LinkContainer = styling("a")({
  width: "100%",
  textDecoration: "none",
  color: "rgba(0, 0, 0, 0.87)",

  "&:hover": {
    cursor: "pointer",
  },
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const JobCardContainer = styling("div")((props) => ({
  position: "relative",

  border: "1px solid" + Color.grey,
  borderRadius: "0.625rem",
  boxShadow: "2px 4px 10px 0px rgba(0, 0, 0, 0.12)",

  width: "100%",
  overflow: "clip",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    border: "1px solid" + Color.hiredlyPurple,
    animation: "none",
  },

  ...(props.$selected && {
    border: "1px solid" + Color.hiredlyPurple,
  }),

  ...(props.$spotlight && {
    "@keyframes gradient-border": {
      "0%": {
        border: "1px solid #6EC197",
      },
      "50%": {
        border: "1px solid #B0CD72",
      },
      "100%": {
        border: "1px solid #6EC197",
      },
    },
    animation: "gradient-border 2s infinite",
  }),

  ...(props.$highlight && {
    border: "1px solid" + Color.hiredlyPurple,
  }),

  "@media (max-width: 640px)": {
    borderRadius: props.$boosted
      ? "0.25rem 0.25rem 0.625rem 0.625rem"
      : "0.625rem",
  },
}));

export const SidebarColor = styling(Box)((props) => ({
  position: "absolute",
  background: props.$highlight ? Color.hiredlyPurple : "#6EC197",

  height: "100%",
  width: "0.5rem",
  zIndex: "9",

  ...(props.$spotlight && {
    "@keyframes sidebarGradient": {
      "0%": { backgroundPosition: "0% 100%" },
      "100%": { backgroundPosition: "0% 0%" },
    },

    animation: "sidebarGradient 1.75s linear infinite",

    backgroundImage: props.$spotlight
      ? "linear-gradient(0deg,transparent,transparent,#B0CD72,transparent,transparent)"
      : `linear-gradient(0deg,${Color.hiredlyPurple},${Color.hiredlyPurple},${Color.chipGreen},${Color.hiredlyPurple},${Color.hiredlyPurple})`,
    backgroundSize: "100% 600%",
  }),

  "@media (max-width: 640px)": {
    height: "10px",
    width: "100%",
  },
}));

export const HideJobContainer = styling(Box)({
  backgroundColor: Color.white,

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  padding: "0.5rem 1rem",
  width: "100%",

  "@media (max-width: 640px)": {
    flexDirection: "column",
    justifyContent: "flex-end",
    gap: "0.25rem",
    padding: "0.375rem 0.5rem",
  },
});

export const TopContainer = styling("div")((props) => ({
  backgroundColor: Color.white,

  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",

  padding: "0.625rem 1rem 0.5rem",
  width: "100%",

  "@media (max-width: 640px)": {
    gap: "0.75rem",
    padding: props.$boosted ? "1.25rem 1rem 0.875rem" : "1rem 1rem 0.875rem",
  },
}));

export const BottomContainer = styling("div")((props) => ({
  background: props.$jobSlotMax
    ? "linear-gradient(90deg, #B0CD72 -0.98%, #6EC197 77.2%)"
    : Color.backgroundGrey,

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  padding: "0.5rem 1rem",
  width: "100%",

  "@media (max-width: 640px)": {
    background: Color.backgroundGrey,
    borderTop: "1px solid" + Color.grey,
    justifyContent: "center",
    padding: "0.75rem 1.25rem 0.875rem",
  },
}));

export const MobileChipContainer = styling("div")((props) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: "0.4375rem",
}));

export const MobileKeywordContainer = styling("div")((props) => ({
  background: props.$jobSlotMax
    ? "linear-gradient(90deg, #B0CD72 -0.98%, #6EC197 77.2%)"
    : Color.backgroundGrey,

  display: "flex",
  justifyContent: "flex-start",
  gap: "0.375rem",
  flexWrap: "wrap",

  padding: "0.5rem 1.25rem",
}));

export const OptionContainer = styling(RadioGroup)({
  flexDirection: "row",
  gap: "10px 40px",

  width: "36rem",
  margin: "0 0 0.625rem",
});

export const RadioStyled = styling(Radio)({
  height: "16px",
  width: "16px",
  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
});

export const OptionLabel = styling(FormControlLabel)({
  gap: "0.5rem",
  flexBasis: "calc(50% - 20px)",
  margin: "0",

  span: {
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
  },
});

export const TextStyled = styling(Typography)({});

export const JobTitleStyled = styling(Typography)({
  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: "1.5rem",
  letterSpacing: "0.1px",
  overflowWrap: "anywhere",

  "@media (max-width: 640px)": {
    fontSize: "1.125rem",
    fontWeight: "600",
    lineHeight: "1.5rem",
  },
});

export const CompanyNameStyled = styling(Typography)({
  flex: "1",

  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  letterSpacing: "0.15px",

  textOverflow: "ellipsis",
  overflowWrap: "anywhere",

  minWidth: "0",

  "@media (max-width: 640px)": {
    color: "rgba(0, 0, 0, 0.60)",
    fontSize: "0.6875rem",
    letterSpacing: "1.5px",
    lineHeight: "1.25rem",
    textTransform: "uppercase",
    overflowX: "hidden",
    whiteSpace: "nowrap",
  },
});

export const PostedTextStyled = styling(Typography)((props) => ({
  color: props.$jobSlotMax ? Color.white : Color.darkGrey,

  fontSize: "0.75rem",
  lineHeight: "1.25rem",

  "@media (max-width: 640px)": {
    color: Color.darkGrey,
    fontSize: "0.625rem",
    fontWeight: "500",
  },
}));

export const AiSummaryButton = styling(ButtonBase)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.3125rem",

  color: props.$selected ? Color.white : Color.hiredlyPurple,
  backgroundColor: props.$selected ? Color.hiredlyPurple : Color.white,
  border: "1px solid" + Color.hiredlyPurple,
  borderRadius: "0.25rem",

  fontSize: "0.625rem",
  lineHeight: "1.25rem",
  letterSpacing: "1.5px",
  textTransform: "uppercase",
  whiteSpace: "nowrap",

  height: "1.375rem",
  padding: "0 0.5rem",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    backgroundColor: Color.hiredlyPurple,
    color: Color.white,
  },
}));

export const BookmarkButton = styling(ButtonBase)({
  color: Color.hiredlyPurple,
  borderRadius: "0.25rem",
});

export const HideButton = styling(ButtonBase)((props) => ({
  color: props.$jobSlotMax ? Color.white : Color.darkGrey,
  borderRadius: "0.25rem",

  display: "flex",
  alignItems: "center",
  gap: "0.25rem",

  fontSize: "0.75rem",
  lineHeight: "1.25rem",

  "&:hover": {
    textDecoration: "underline",
  },
}));

export const TextButton = styling(ButtonBase)((props) => ({
  color: props.$grey ? Color.darkGrey : Color.hiredlyPurple,
  border: "none",
  borderRadius: "0.25rem",

  fontSize: "0.75rem",
  fontWeight: "700",
  whiteSpace: "nowrap",

  height: "1.5rem",
  padding: "0 0.625rem",

  ...(props.disabled && {
    color: Color.darkGrey,
    cursor: "not-allowed",
    opacity: "0.5",
    pointerEvents: "none",
  }),
}));

export const MobileApplyButton = styling(ButtonBase)((props) => ({
  display: "flex",
  alignItems: "center",

  color: Color.white,
  backgroundColor: "rgba(110, 193, 151, 1)",
  border: "1px solid" + Color.black,
  borderRadius: "6.25rem",

  fontSize: "0.875rem",
  fontWeight: 700,
  textTransform: "capitalize",

  height: "2.25rem",
  width: "10rem",
  padding: "0 1rem",
}));

export const CompanyLogoStyled = styling("img")({
  border: "0.5px solid rgba(0, 0, 0, 0.38)",
  borderRadius: "0.375rem",
  width: "56px",
  height: "56px",

  "@media (max-width: 640px)": {
    width: "28px",
    height: "28px",
  },
});

export const HighlightChip = styling("div")({
  display: "flex",
  alignItems: "center",

  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  borderRadius: "0.3125rem",

  height: "1.5rem",
  padding: "0 0.3125rem",
});

export const SpotlightChip = styling("div")({
  display: "flex",
  alignItems: "center",

  color: Color.white,
  background: "linear-gradient(242deg, #6EC7CD 14.33%, #B0CD72 76.81%)",
  borderRadius: "0.3125rem",

  height: "1.5rem",
  padding: "0 0.3125rem",
});

export const GlobalChip = styling("div")({
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",

  color: Color.white,
  backgroundColor: "#F9CA10",
  borderRadius: "6.25rem",

  fontSize: "0.625rem",
  fontWeight: "700",
  lineHeight: "1.25rem",
  letterSpacing: "1.5px",
  textTransform: "uppercase",

  height: "1.5rem",
  padding: "0 0.5rem 0 0.375rem",
});

export const JobTypeChip = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",

  backgroundColor: props.$jobSlotMax ? Color.white : "rgba(33, 61, 181, 0.14)",
  color: Color.blue,
  borderRadius: "6.25rem",

  fontSize: "0.625rem",
  fontWeight: "700",
  textWrap: "nowrap",

  height: "1.5rem",
  padding: "0 0.625rem",
}));

export const KeywordChip = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",

  backgroundColor: props.$jobSlotMax ? Color.white : Color.lightHiredlyPurple,
  color: "rgba(0, 0, 0, 0.60)",
  border: "0.5px solid rgba(0, 0, 0, 0.60)",
  borderRadius: "0.25rem",

  fontSize: "0.75rem",
  fontWeight: "300",
  lineHeight: "1.25rem",
  textWrap: "nowrap",

  height: "1.25rem",
  padding: "0.375rem",
}));

export const MobileDetailChip = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "0.25rem",

  fontSize: "0.75rem",
  fontWeight: "700",
  whiteSpace: "nowrap",

  height: "1.3125rem",
  padding: "0 0.625rem",

  ...(props.type === "jobType" && {
    color: Color.blue,
    backgroundColor: "rgba(33, 61, 181, 0.14)",
  }),
  ...(props.type === "state" && {
    color: "rgba(129, 155, 72, 1)",
    backgroundColor: "rgba(129, 155, 72, 0.14)",
  }),
  ...(props.type === "salary" && {
    color: "rgba(155, 72, 72, 1)",
    backgroundColor: "rgba(155, 72, 72, 0.14)",
  }),
}));

export const DividerStyled = styling(Divider)((props) => ({
  background: props.$jobSlotMax ? Color.white : Color.lightGrey,
  height: "1rem",
}));

export const CircularProgressStyled = styling(CircularProgress)({
  "&.MuiCircularProgress-root": {
    width: "24px !important",
    height: "24px !important",
  },
});

export const HighlightIcon = styling(FireIcon)({
  height: "14px",
  width: "14px",
});

export const SpotlightIcon = styling(SparklesIcon)({
  height: "14px",
  width: "14px",
});

export const GlobalIcon = styling(GlobeAsiaAustraliaIcon)({
  height: "14px",
  width: "14px",
});

export const DollarIcon = styling(AttachMoneyIcon)({
  color: "rgba(0, 0, 0, 0.60)",
  height: "20px",
  width: "auto",
});

export const LocationIcon = styling(MapPinIcon)({
  color: "rgba(0, 0, 0, 0.60)",
  height: "20px",
  width: "auto",
});

export const AiSparklesIcon = styling(SparklesOutlineIcon)({
  height: "16px",
  width: "16px",
});

export const SaveOutlineIcon = styling(BookmarkOutlineIcon)({
  height: "24px",
  width: "24px",

  margin: "0 -6px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    strokeWidth: 2,
  },
});

export const SaveFilledIcon = styling(BookmarkFilledIcon)({
  height: "24px",
  width: "24px",

  margin: "0 -6px",
});

export const HideIcon = styling(EyeSlashIcon)({
  height: "16px",
  width: "16px",

  "@media (max-width: 640px)": {
    height: "24px",
    width: "24px",
  },
});

export const LeftArrowIcon = styling(ArrowLeftIcon)({
  height: "20px",
  width: "20px",
});

export const CloseIcon = styling(XMarkIcon)({
  height: "20px",
  width: "20px",
});

export const ArrowIconStyled = styling(ArrowTopRightOnSquareIcon)({
  height: "19px",
  width: "20px",
  margin: "0 -6px 0 6px",
});
