import { useDispatch } from "react-redux";
import { updateSelectAllJobFilter } from "../../../redux/actions/job_action";
import {
  BoxContainer,
  ButtonContainer,
  CheckStyled,
  CheckboxContainer,
  CheckboxStyled,
  CheckedIcon,
  FilterActionButton,
  JobSearchOptionsMenuBox,
  TextStyled,
  UncheckedIcon,
} from "./styles";

function JobSearchOptionsMenu(props) {
  const {
    id,
    jobListFilter,
    type,
    filterArray,
    onTriggerLoadJob,
    checkboxValueChange,
    isFilterSelected,
    getFilter,
    getFilterValue,
    handleCancelClick,
  } = props;

  let trackIds = [];
  let states = [];
  let jobTypesVar = [];
  let experienceLevelsVar = [];

  const dispatch = useDispatch();

  const selectedFilters = getFilter(jobListFilter, type);

  const getAllTrackIds = () => {
    let x = 0;

    while (x < filterArray?.length) {
      trackIds.push(parseInt(filterArray[x]?.id));
      x++;
    }
  };

  const getAllStates = () => {
    let x = 0;

    while (x < filterArray?.length) {
      states.push(filterArray[x]?.state);
      x++;
    }
  };

  const getAllJobTypes = () => {
    let x = 0;

    while (x < filterArray?.length) {
      jobTypesVar.push(parseInt(filterArray[x]?.id)); // Id's need to be parsed/converted to integers
      x++;
    }
  };

  const getAllExperienceLevels = () => {
    let x = 0;

    while (x < filterArray?.length) {
      experienceLevelsVar.push(parseInt(filterArray[x]?.id)); // Id's need to be parsed/converted to integers
      x++;
    }
  };

  const getFilterKey = (type) => {
    let filterKey;
    switch (type) {
      case "track":
        filterKey = "trackIds";
        break;
      case "state":
        filterKey = "stateRegions";
        break;
      case "job-type":
        filterKey = "jobTypeIds";
        break;
      case "experience":
        filterKey = "experienceIds";
        break;
      default:
        return;
    }
    return filterKey;
  };

  const handleSelectAll = (type) => {
    const filterKey = getFilterKey(type);
    let cJobListFilter = { ...jobListFilter };

    switch (type) {
      case "track":
        getAllTrackIds();
        cJobListFilter[filterKey] = trackIds;
        break;
      case "state":
        getAllStates();
        cJobListFilter[filterKey] = states;
        break;
      case "job-type":
        getAllJobTypes();
        cJobListFilter[filterKey] = jobTypesVar;
        break;
      case "experience":
        getAllExperienceLevels();
        cJobListFilter[filterKey] = experienceLevelsVar;
        break;
      default:
        return;
    }

    dispatch(updateSelectAllJobFilter(cJobListFilter));
  };

  const handleDeselectAll = (type) => {
    const filterKey = getFilterKey(type);
    let cJobListFilter = { ...jobListFilter };
    cJobListFilter[filterKey] = [];
    dispatch(updateSelectAllJobFilter(cJobListFilter));
  };

  const renderSelectAndDeselectAllButton = (type) => {
    const filterKey = getFilterKey(type);
    const isEmpty =
      !jobListFilter[filterKey] || jobListFilter[filterKey]?.length === 0;

    return (
      <>
        <FilterActionButton
          onClick={
            isEmpty
              ? () => handleSelectAll(type)
              : () => handleDeselectAll(type)
          }
          variant="text"
        >
          {isEmpty ? "Select All" : "Deselect All"}
        </FilterActionButton>
      </>
    );
  };

  return (
    <JobSearchOptionsMenuBox id={id}>
      <ButtonContainer
        justifyContent={"flex-end"}
        padding={"1rem 1.5rem 0.625rem 0.625rem"}
      >
        {renderSelectAndDeselectAllButton(type)}
      </ButtonContainer>
      <BoxContainer
        flexDirection={"column"}
        maxHeight={type === "state" ? "12.5rem" : "auto"}
        sx={{ overflowY: "scroll", overflowX: "hidden" }}
      >
        {filterArray.map((filterItem, index) => (
          <>
            <CheckboxContainer
              key={index}
              onClick={checkboxValueChange(type, filterItem)}
            >
              <CheckboxStyled
                icon={<UncheckedIcon />}
                checked={isFilterSelected(
                  selectedFilters,
                  getFilterValue(type, filterItem)
                )}
                checkedIcon={
                  <CheckedIcon>
                    <CheckStyled />
                  </CheckedIcon>
                }
              />
              <TextStyled
                fontSize={"0.875rem"}
                lineHeight={"1.5rem"}
                letterSpacing={"0.15px"}
              >
                {filterItem.state || filterItem.jobType || filterItem.title}
              </TextStyled>
            </CheckboxContainer>
          </>
        ))}
      </BoxContainer>
      <ButtonContainer
        justifyContent={"space-between"}
        padding={"1.5rem 1.5rem 1rem"}
      >
        <FilterActionButton
          onClick={() => handleCancelClick()}
          variant="outline"
        >
          Cancel
        </FilterActionButton>
        <FilterActionButton onClick={() => onTriggerLoadJob()} variant="filled">
          Apply
        </FilterActionButton>
      </ButtonContainer>
    </JobSearchOptionsMenuBox>
  );
}

export default JobSearchOptionsMenu;
