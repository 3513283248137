import useMediaQuery from "@mui/material/useMediaQuery";
import posthog from "posthog-js";
import { useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { assetDomain } from "../../../config/config";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { getUserWorkPersona } from "../../../redux/actions/user_action";
import {
  BoxContainer,
  CardContainer,
  CloseButton,
  CloseIcon,
  ImageStyled,
  SingleSparkleIcon,
  SparklesIconStyled,
  TextStyled,
  TypeformButton,
} from "./styles";

function JobWorkPersonaCard() {
  var typeformId = process.env.NEXT_PUBLIC_WORK_PERSONA_ID;

  const isMobile = useMediaQuery("(max-width: 640px)");

  const personaImage = assetDomain + "/images/work-persona-asset.png";

  const [showCard, setShowCard] = useState(true);
  const [triggerImpression, setTriggerImpression] = useState(true);

  const reduxUserName = useSelector((state) => state.user?.user?.name);
  const reduxUserEmail = useSelector((state) => state.user?.user?.email);
  const reduxUserPersona = useSelector((state) => state.user?.workPersona);

  const dispatch = useDispatch();

  return (
    <CardContainer
      visibility={showCard && Object.keys(reduxUserPersona).length <= 0}
    >
      <BoxContainer
        position={"relative"}
        alignItems={"flex-start"}
        gap={isMobile ? "0.5rem" : "0.625rem"}
        sx={{ width: isMobile ? "100%" : "75%" }}
      >
        <ImageStyled src={personaImage} />
        <BoxContainer
          flexDirection={isMobile ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={isMobile ? "0.75rem" : "2.25rem"}
        >
          <BoxContainer flexDirection={"column"} gap={"0.125rem"}>
            <TextStyled
              fontSize={isMobile ? "1rem" : "1.5625rem"}
              fontWeight={"500"}
              lineHeight={isMobile ? "1.5rem" : "2rem"}
              letterSpacing={"0.1px"}
            >
              What's Your Career Personality?
            </TextStyled>
            <TextStyled
              fontSize={isMobile ? "0.875rem" : "1rem"}
              lineHeight={isMobile ? "1.25rem" : "1.5rem"}
              letterSpacing={"0.15px"}
            >
              Discover your career path, based on who you are.
            </TextStyled>
          </BoxContainer>
        </BoxContainer>
      </BoxContainer>

      <BoxContainer
        justifyContent={"center"}
        alignItems={"center"}
        marginRight={isMobile ? "0" : "2.5rem"}
        sx={{ width: isMobile ? "100%" : "auto" }}
        color={"#BF8A00"}
      >
        <InView
          trackVisibility={true}
          threshold={1}
          delay={300}
          onChange={(inView, entry) => {
            // impression should only trigger when triggerimpression is true
            // if triggerimpression is false, means user's last action is opening persona popup
            if (inView === true && entry?.isVisible === true) {
              if (triggerImpression !== true) {
                return setTriggerImpression(true);
              }

              sendTrackingEvent({
                event: "CE_persona_quiz_card_impression",
              });

              posthog.capture("persona_quiz_card_impression");
              // set triggerimpression to false as we don't want impression to trigger when closing persona popup
              setTriggerImpression(false);
            }
          }}
        >
          <TypeformButton
            className="quiz-button"
            id={typeformId}
            hidden={{
              fullname: reduxUserName,
              email: reduxUserEmail,
            }}
            onReady={() => {
              sendTrackingEvent({
                event: "CE_persona_quiz_card_click",
              });

              posthog.capture("persona_quiz_card_click");
            }}
            onSubmit={() => {
              posthog.capture("persona_quiz_complete", {
                persona: reduxUserPersona?.attributes?.personaId ?? null,
                first_time: reduxUserPersona?.attributes?.personaId
                  ? false
                  : true,
              });

              sendTrackingEvent({
                event: "CE_persona_quiz_complete",
                persona: reduxUserPersona.attributes?.personaId ?? null,
                first_time: reduxUserPersona.attributes?.personaId
                  ? false
                  : true,
              });
            }}
            onClose={() => {
              reduxUserEmail ? dispatch(getUserWorkPersona()) : null;
            }}
          >
            Take the Quiz <SparklesIconStyled />
          </TypeformButton>
        </InView>
        <SingleSparkleIcon
          sx={
            isMobile
              ? {
                  bottom: "22px",
                  left: "22px",
                  height: "20px",
                  width: "20px",
                }
              : {
                  top: "27px",
                  right: "224px",
                  height: "20px",
                  width: "20px",
                }
          }
        />
        <SingleSparkleIcon
          sx={
            isMobile
              ? {
                  bottom: "40px",
                  left: "40px",
                  height: "16px",
                  width: "16px",
                }
              : {
                  top: "13px",
                  right: "210px",
                  height: "16px",
                  width: "16px",
                }
          }
        />
        <SingleSparkleIcon
          sx={{
            bottom: isMobile ? "4px" : "12px",
            right: isMobile ? "28px" : "28px",
            height: "20px",
            width: "20px",
          }}
        />
      </BoxContainer>

      <CloseButton
        onClick={() => {
          sendTrackingEvent({
            event: "CE_persona_quiz_card_close",
          });
          posthog.capture("persona_quiz_card_close");

          setShowCard(false);
        }}
      >
        <CloseIcon />
      </CloseButton>
    </CardContainer>
  );
}

export default JobWorkPersonaCard;
