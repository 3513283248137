import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateFetchingJobListStatus,
  updateJobListFilter,
  updateSearchFeedback,
} from "../../../redux/actions/job_action";
import AccordionJobCard from "../AccordionJobCard/AccordionJobCard";
import OldAccordionJobCard from "../AccordionJobCard/deprecate-soon/OldAccordionJobCard";
import { AiSparklesIcon, AiSummaryButton } from "../AccordionJobCard/styles";
import AiJobSummary from "../AiJobSummary/AiJobSummary";
import OldAiJobSummary from "../AiJobSummary/deprecate-soon/OldAiJobSummary";
import JobAlertToggle from "../JobAlertToggle/JobAlertToggle";
import JobSearchFeedbackCard from "../JobSearchFeedbackCard/JobSearchFeedbackCard";
import JobWorkPersonaCard from "../JobWorkPersonaCard/JobWorkPersonaCard";
import {
  AccordionJobListSkeleton,
  AccordionMobileJobListSkeleton,
  AiJobSummaryEmptySkeleton,
} from "../Skeleton/Skeleton";
import {
  AiArrowImage,
  AiFileImage,
  AiFileImageBox,
  AiSummaryEmptyBox,
  AiSummaryEmptyText,
  AiSummaryWrapper,
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  EmptyContainer,
  IconButtonStyled,
  JobListContainer,
  PageNumber,
  PaginationNumberBox,
  Wrapper,
} from "./styles";

function AccordionJobList(props) {
  const {
    isTabLoading,
    filterParams,
    loadingJobs,
    onFollowJobClicked,
    onApplyJobClicked,
    unSaveJob,
    currentId,
    jobPreferenceToggle,
  } = props;

  const dispatch = useDispatch();
  const router = useRouter();

  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const isMobile = useMediaQuery("(max-width: 640px)");

  const jobs = useSelector((state) => state.jobs.jobs);
  const isLoadingJobs = useSelector((state) => state.jobs.isLoadingJobs);
  const fetchingJobs = useSelector((state) => state.jobs.fetchingJobList);
  const jobPageInfo = useSelector((state) => state.jobs.jobPageInfoV1);
  const currentPage = useSelector((state) => state.jobs.jobCurrentPage);

  const reduxUserPersona = useSelector((state) => state.user?.workPersona);
  const noWorkPersona = Object.keys(reduxUserPersona).length <= 0;

  const showSearchFeedback = useSelector(
    (state) => state.jobs.searchFeedback.showCard
  );
  const submitSearchFeedback = useSelector(
    (state) => state.jobs.searchFeedback.feedbackSubmit
  );

  const jobCardVariant = useFeatureFlagVariantKey("new-hover-card-ai-summary");

  const keywordExist =
    useSelector((state) => state?.jobs?.jobListFilter["keyword"])?.length > 0;

  const [showAiSummary, setShowAiSummary] = useState(false);
  const [jobIndex, setJobIndex] = useState("");

  const [firstAlertBannerVisible, setFirstAlertBannerVisible] = useState(true);
  const [secondAlertBannerVisible, setSecondAlertBannerVisible] =
    useState(true);
  const [thirdAlertBannerVisible, setThirdAlertBannerVisible] = useState(true);

  function jobListNextPage() {
    sendTrackingEvent({
      event: "CE_clicked-jobs-page",
      "page-number": currentPage,
      action: "next",
    });

    parsePageUrl("next");
    dispatch(updateFetchingJobListStatus(true));

    if (submitSearchFeedback) {
      dispatch(updateSearchFeedback({ feedbackSubmit: true, showCard: false }));
    }
    setShowAiSummary(false);
    window.scrollTo(0, 0);
  }

  function jobListPreviousPage() {
    sendTrackingEvent({
      event: "CE_clicked-jobs-page",
      "page-number": currentPage,
      action: "previous",
    });

    parsePageUrl("previous");
    dispatch(updateFetchingJobListStatus(true));

    if (submitSearchFeedback) {
      dispatch(updateSearchFeedback({ feedbackSubmit: true, showCard: false }));
    }
    setShowAiSummary(false);
    window.scrollTo(0, 0);
  }

  function parsePageUrl(pageDirection) {
    const query = router.query;
    const { filter, ...queryParams } = query;
    const { page, ...rest } = queryParams;

    const slug = filter.join("/");

    const newPage =
      pageDirection === "next" ? currentPage + 1 : currentPage - 1;
    const isFirstPage = newPage === 1 ? true : false;

    router.push(
      {
        pathname: slug,
        query: {
          ...rest,
          ...(!isFirstPage && { page: newPage }),
        },
      },
      undefined,
      { shallow: true }
    );
  }

  const scrollToComponent = (index) => {
    const componentRef = document.getElementById(index);
    var headerOffset = 155;
    var elementPosition = componentRef.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  function applyFilterParams(params) {
    // const cParams = { ...jobListFilter, ...params };
    const cParams = params;
    dispatch(updateJobListFilter(cParams)).then((res) => {
      if (isLoadingJobs === undefined) {
        return;
      }
      loadingJobs(true);
    });
  }

  useEffect(() => {
    if (filterParams !== undefined && !isTabLoading) {
      if (Object.keys(filterParams).length === 0) {
        return loadingJobs(true);
      }
      return applyFilterParams(filterParams);
    }
  }, [filterParams]);

  return (
    <Wrapper>
      <JobListContainer>
        {fetchingJobs || isLoadingJobs ? (
          Array.from({ length: 10 }).map((_, index) => {
            return isMobile ? (
              <AccordionMobileJobListSkeleton key={index} />
            ) : (
              <AccordionJobListSkeleton key={index} />
            );
          })
        ) : (
          <>
            {firstAlertBannerVisible &&
            fetchingJobs === false &&
            jobs?.length > 0 ? (
              <JobAlertToggle
                jobAlertBannerId={`job-alert-banner-1`}
                setBannerVisible={setFirstAlertBannerVisible}
              />
            ) : null}
            {jobs.map((job, index) => {
              return (
                <Fragment key={index}>
                  {/* only show the persona quiz if user has not taken the quiz */}
                  {index === 4 && fetchingJobs === false && noWorkPersona && (
                    <JobWorkPersonaCard />
                  )}
                  {index === 8 &&
                  secondAlertBannerVisible &&
                  fetchingJobs === false ? (
                    <JobAlertToggle
                      jobAlertBannerId={`job-alert-banner-${index}`}
                      setBannerVisible={setSecondAlertBannerVisible}
                    />
                  ) : index === 16 &&
                    thirdAlertBannerVisible &&
                    fetchingJobs === false ? (
                    <JobAlertToggle
                      jobAlertBannerId={`job-alert-banner-${index}`}
                      setBannerVisible={setThirdAlertBannerVisible}
                    />
                  ) : null}

                  {jobCardVariant === "hover-job-card" ? (
                    <AccordionJobCard
                      id={index}
                      job={job}
                      index={index}
                      onFollowJobClicked={onFollowJobClicked}
                      onApplyJobClicked={onApplyJobClicked}
                      unSaveJob={unSaveJob}
                      currentId={currentId}
                      setShowAiSummary={setShowAiSummary}
                      showAiSummary={showAiSummary}
                      jobPreferenceToggle={jobPreferenceToggle}
                      setJobIndex={setJobIndex}
                      origin={"job_list"}
                    />
                  ) : (
                    <OldAccordionJobCard
                      id={index}
                      job={job}
                      index={index}
                      onFollowJobClicked={onFollowJobClicked}
                      onApplyJobClicked={onApplyJobClicked}
                      unSaveJob={unSaveJob}
                      currentId={currentId}
                      setShowAiSummary={setShowAiSummary}
                      showAiSummary={showAiSummary}
                      jobPreferenceToggle={jobPreferenceToggle}
                      setJobIndex={setJobIndex}
                      origin={"job_list"}
                    />
                  )}
                </Fragment>
              );
            })}
            {jobs?.length > 0 && keywordExist && showSearchFeedback && (
              <JobSearchFeedbackCard />
            )}

            {fetchingJobs ? null : jobs?.length > 0 ? (
              <EmptyContainer
                sx={{ width: "100%", marginTop: "25px" }}
                container
                direction="row"
                justifyContent="center"
              >
                {jobPageInfo?.hasPreviousPage ? (
                  <Fragment>
                    <IconButtonStyled
                      $margin_right="true"
                      $pagination="true"
                      disableRipple
                      onClick={() => jobListPreviousPage()}
                      disabled={!jobPageInfo?.hasPreviousPage}
                    >
                      <ChevronLeftIconStyled />
                    </IconButtonStyled>
                    <PaginationNumberBox $non_focus="true">
                      <PageNumber variant="subtitle2">
                        {currentPage - 1}
                      </PageNumber>
                    </PaginationNumberBox>
                  </Fragment>
                ) : null}

                <PaginationNumberBox $margin="true">
                  <PageNumber variant="subtitle2">{currentPage}</PageNumber>
                </PaginationNumberBox>

                {jobPageInfo?.hasNextPage && (
                  <Fragment>
                    <PaginationNumberBox $non_focus="true">
                      <PageNumber variant="subtitle2">
                        {currentPage + 1}
                      </PageNumber>
                    </PaginationNumberBox>
                    <IconButtonStyled
                      $margin_left="true"
                      $pagination="true"
                      disableRipple
                      onClick={() => jobListNextPage()}
                      disabled={!jobPageInfo?.hasNextPage}
                    >
                      <ChevronRightIconStyled />
                    </IconButtonStyled>
                  </Fragment>
                )}
              </EmptyContainer>
            ) : (
              <JobAlertToggle noSearchResults loadingJobs={fetchingJobs} />
            )}
          </>
        )}
      </JobListContainer>
      {jobCardVariant === "hover-job-card" ? (
        isDesktop ? (
          <AiJobSummary />
        ) : null
      ) : fetchingJobs ? (
        <AiJobSummaryEmptySkeleton />
      ) : showAiSummary ? (
        <OldAiJobSummary
          setShowAiSummary={setShowAiSummary}
          showAiSummary={showAiSummary}
          onApplyJobClicked={onApplyJobClicked}
          jobIndex={jobIndex}
          scrollToComponent={scrollToComponent}
        />
      ) : (
        jobs?.length > 0 && (
          <AiSummaryWrapper>
            <AiFileImageBox>
              <AiFileImage
                src={config.assetDomain + "/images/hiredly/ai-files.svg"}
                alt="ai files"
              />
            </AiFileImageBox>
            <AiSummaryEmptyBox>
              <AiSummaryEmptyText>Click</AiSummaryEmptyText>
              <AiSummaryButton>
                Job Summary <AiSparklesIcon />
              </AiSummaryButton>
              <AiSummaryEmptyText>to see job overview.</AiSummaryEmptyText>

              <AiArrowImage
                src={config.assetDomain + "/images/hiredly/ai-arrow.svg"}
                alt="arrow"
              />
            </AiSummaryEmptyBox>
          </AiSummaryWrapper>
        )
      )}
    </Wrapper>
  );
}

export default AccordionJobList;
