import ArrowTopRightOnSquareIcon from "@heroicons/react/20/solid/ArrowTopRightOnSquareIcon";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import SparklesIcon from "@heroicons/react/24/outline/SparklesIcon";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import Link from "next/link";
import styled from "styled-components";
import Color from "../../../../assets/colors";

// Button Component //
export const ApplyJobButton = styling(ButtonBase)((props) => ({
  color: Color.white,
  backgroundColor: "#6EC197",
  border: `1.5px solid ${Color.black}`,
  borderRadius: "6.25rem",

  fontSize: "1rem",
  fontWeight: 700,
  textTransform: "capitalize",

  height: "3rem",
  width: "12.5rem",

  ...(props.disabled && {
    color: Color.darkGrey,
    backgroundColor: Color.grey,
    opacity: 0.5,
    cursor: "not-allowed",
  }),

  transition: "all 0.125s ease-in-out",

  "&:hover": {
    backgroundColor: Color.buttonHoverGreen,
  },
}));

// Span Component //
export const AiSummaryCompanyText = styling(Link)((props) => ({
  color: props["aria-disabled"] ? Color.black : Color.hiredlyPurple,
  pointerEvents: props["aria-disabled"] ? "none" : "auto",
  textDecoration: props["aria-disabled"] ? "none" : "underline",
  fontWeight: "700",
  fontSize: "0.875rem",
}));

// Icon Button Component //
export const CloseButton = styling(IconButton)({
  width: "2.5rem",
  height: "2.5rem",
});

// Icon Component //
export const SparklesIconStyled = styling(SparklesIcon)({
  width: "1rem",
  height: "1rem",
});

export const InformationCircleIconStyled = styling(InformationCircleIcon)({
  //
});

export const XMarkIconStyled = styling(CloseIcon)({
  //
});

// Box Components //
export const AiSummaryContainer = withTheme(styled(Box)`
  && {
    position: ${(props) =>
      props.$origin === "single-job-page" ? "relative" : "sticky"};
    top: ${(props) => (props.$origin === "single-job-page" ? null : "9.5rem")};
    width: ${(props) => (props.$origin === "single-job-page" ? null : "100%")};
    max-width: ${(props) =>
      props.$origin === "single-job-page" ? "auto" : "21.25rem"};
    grid-template-rows: ${(props) =>
      props.$origin === "single-job-page" ? "auto auto" : "auto auto 1fr auto"};
    grid-template-columns: ${(props) =>
      props.$origin === "single-job-page" ? "minmax(0, 1fr)" : null};
    border-radius: 10px;
    padding: 0.75rem;
    border: 2px solid ${Color.black};
    background-color: hsla(43, 83%, 73%, 0.1);
    display: grid;
    row-gap: 0.75rem;
    height: fit-content;
  }
`);

export const AiSummaryHeader = withTheme(styled(Box)`
  && {
    display: ${(props) =>
      props.$origin === "single-job-page" ? "none" : "grid"};
    grid-template-columns: 1fr auto;
    align-items: center;
  }
`);

export const AiSummaryTextContainer = styling(Box)({
  position: "relative",
  backgroundColor: Color.white,
  border: `2px solid ${Color.black}`,
  borderRadius: "0 1.25rem 1.25rem 1.25rem",
  padding: "1rem",
});

export const AiSummaryTitleContainer = styling(Box)({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  alignItems: "center",
  columnGap: "0.5rem",
});

export const AiSummaryActionButtons = withTheme(styled(Box)`
  && {
    display: ${(props) =>
      props.$origin === "single-job-page" ? "none" : "grid"};
    grid-template-columns: 1fr;
    justify-items: center;
  }
`);

export const AiSummaryTitle = styling(Box)((props) => ({
  position: "relative",
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  columnGap: "0.25rem",
  padding: "0 0.5rem",
  alignItems: "center",

  height: "40px",
  borderRadius: "0.7rem",
  backgroundColor: "#F9E3AB",
  border: `2px solid ${Color.black}`,
  boxShadow: `2px 2px 0 ${Color.black}`,

  [props.theme.breakpoints.down("lg")]: {
    width: "15.5rem",
  },
}));

// Image Component //
export const AshleyImage = styling("img")({
  border: `2px solid ${Color.black}`,
  borderRadius: "50%",
});

export const SpeechTail = styling("img")({
  position: "absolute",
  left: "0",
  top: "0",
  transform: "translate(-14%, -54%)",
});

// Typography Component //
export const AiSummaryJobText = styling(Typography)({
  fontWeight: "700",
  fontSize: "0.875rem",
  lineHeight: "1.5rem",
});

export const AiSummaryTitleText = styling(Typography)({
  fontWeight: "700",
  fontSize: "1rem",
});

export const AiSummaryText = styling(Typography)({
  fontWeight: "400",
  fontSize: "1rem",
  lineHeight: "1.5rem",
});

export const BetaText = styling(Typography)((props) => ({
  position: "absolute",
  top: "0",
  right: "0",
  transform: "translate(-100%, 25%)",
  fontWeight: "700",
  fontSize: "0.5rem",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
  color: Color.hiredlyPurple,

  [props.theme.breakpoints.down("lg")]: {
    transform: "translate(-32%, 50%)",
  },
}));

export const ArrowIconStyled = styling(ArrowTopRightOnSquareIcon)({
  height: "19px",
  width: "20px",
  margin: "0 -6px 0 6px",
});

export const CustomTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "20px",
    padding: "0px",
    backgroundColor: Color.white,
    color: "black",
    zIndex: 999,
    cursor: "none",
  },
});

export const DivStyled = styling(Grid)((props) => ({
  //
}));
