import { decode } from "html-entities";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import * as config from "../../../../config/config";
import AiJobSummaryError from "../../AiJobSummaryError/AiJobSummaryError";
import { AiJobSummarySkeleton } from "../../Skeleton/Skeleton";
import {
  AiSummaryActionButtons,
  AiSummaryCompanyText,
  AiSummaryContainer,
  AiSummaryHeader,
  AiSummaryJobText,
  AiSummaryText,
  AiSummaryTextContainer,
  AiSummaryTitle,
  AiSummaryTitleContainer,
  AiSummaryTitleText,
  ApplyJobButton,
  ArrowIconStyled,
  AshleyImage,
  BetaText,
  CloseButton,
  SparklesIconStyled,
  SpeechTail,
  XMarkIconStyled,
} from "./styles";

function OldAiJobSummary(props) {
  const {
    setShowAiSummary,
    showAiSummary,
    origin,
    onApplyJobClicked,
    jobIndex,
    scrollToComponent,
  } = props;

  const currentJob = useSelector((state) => state.jobs?.job);
  const fetchingJob = useSelector((state) => state.jobs?.fetchingJob);
  const applyingJob = useSelector((state) => state.jobs?.applyingJob);
  const reduxUser = useSelector((state) => state.user?.user);

  function handleClose(event) {
    event.stopPropagation();
    setShowAiSummary(!showAiSummary);
  }

  function handleApplyJob(event, job, reduxUser) {
    event.stopPropagation();
    event.preventDefault();

    onApplyJobClicked(job, reduxUser);
  }

  // To convert the hexadecimal characters and HTML entities from backend to string format
  function convertHexAndEntitiesToString(gptSummary) {
    let regexHex = /&#x([0-9a-fA-F]+);/g;

    let result = gptSummary.replace(regexHex, function (match, hexValue) {
      let char = String.fromCharCode(parseInt(hexValue, 16));
      return char;
    });

    return decode(result);
  }

  return (
    <Fragment>
      {fetchingJob ? (
        <AiJobSummarySkeleton />
      ) : !currentJob?.gptSummary ? (
        <AiJobSummaryError />
      ) : (
        <AiSummaryContainer
          $origin={origin}
          onClick={() => scrollToComponent(jobIndex)}
        >
          <AiSummaryHeader $origin={origin}>
            <AiSummaryJobText>
              {currentJob?.title} @{" "}
              <AiSummaryCompanyText
                href={`/companies/${currentJob?.company?.slug}`}
                aria-disabled={currentJob?.company?.hide ? true : false}
                target="_blank"
                rel="noopener noreferrer"
              >
                {currentJob?.company?.name}
              </AiSummaryCompanyText>
            </AiSummaryJobText>
            <CloseButton onClick={handleClose}>
              <XMarkIconStyled />
            </CloseButton>
          </AiSummaryHeader>

          <AiSummaryTitleContainer>
            <AshleyImage
              src={config.assetDomain + "/images/hiredly/ashley-image.svg"}
              alt="ashley"
            />
            <AiSummaryTitle>
              <SparklesIconStyled />
              <AiSummaryTitleText>AI-generated summary</AiSummaryTitleText>
              <BetaText>beta</BetaText>
            </AiSummaryTitle>
          </AiSummaryTitleContainer>

          <AiSummaryTextContainer>
            <SpeechTail
              src={config.assetDomain + "/images/hiredly/speech-tail-bg.svg"}
              alt="speech tail"
            />
            <AiSummaryText>
              {convertHexAndEntitiesToString(currentJob?.gptSummary)}
            </AiSummaryText>
          </AiSummaryTextContainer>

          <AiSummaryActionButtons $origin={origin}>
            <ApplyJobButton
              disabled={applyingJob || currentJob.haveApplied}
              onClick={(event) => handleApplyJob(event, currentJob, reduxUser)}
              variant="contained"
            >
              {currentJob.haveApplied ? (
                "applied"
              ) : currentJob?.category === "scraped" ||
                currentJob?.category === "aggregated" ? ( // Later need to put checking for external job
                <Fragment>
                  {"Apply"}
                  <ArrowIconStyled />
                </Fragment>
              ) : (
                "apply"
              )}
            </ApplyJobButton>
          </AiSummaryActionButtons>
        </AiSummaryContainer>
      )}
    </Fragment>
  );
}

export default OldAiJobSummary;
